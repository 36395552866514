import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45a8e66c = () => interopDefault(import('../src/pages/banner-block/index.vue' /* webpackChunkName: "pages/banner-block/index" */))
const _c973bdae = () => interopDefault(import('../src/pages/calculators/index.vue' /* webpackChunkName: "pages/calculators/index" */))
const _0e7cc40d = () => interopDefault(import('../src/pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _3dca5e6c = () => interopDefault(import('../src/pages/event-triggers/index.vue' /* webpackChunkName: "pages/event-triggers/index" */))
const _2dbfe6cd = () => interopDefault(import('../src/pages/feeds/index.vue' /* webpackChunkName: "pages/feeds/index" */))
const _e1ab703c = () => interopDefault(import('../src/pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _2e484bbc = () => interopDefault(import('../src/pages/list-waiting/index.vue' /* webpackChunkName: "pages/list-waiting/index" */))
const _1e62819e = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0d92993c = () => interopDefault(import('../src/pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _796575a1 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _9cfbe3fa = () => interopDefault(import('../src/pages/raffles/index.vue' /* webpackChunkName: "pages/raffles/index" */))
const _8d2c5694 = () => interopDefault(import('../src/pages/review/index.vue' /* webpackChunkName: "pages/review/index" */))
const _fcbb2ade = () => interopDefault(import('../src/pages/search-text-replace/index.vue' /* webpackChunkName: "pages/search-text-replace/index" */))
const _12907e12 = () => interopDefault(import('../src/pages/sending/index.vue' /* webpackChunkName: "pages/sending/index" */))
const _f6af2456 = () => interopDefault(import('../src/pages/seo/index.vue' /* webpackChunkName: "pages/seo/index" */))
const _32d25301 = () => interopDefault(import('../src/pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _29717f94 = () => interopDefault(import('../src/pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _51b5f880 = () => interopDefault(import('../src/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _6d5ccb27 = () => interopDefault(import('../src/pages/widgets/index.vue' /* webpackChunkName: "pages/widgets/index" */))
const _f19be86a = () => interopDefault(import('../src/pages/calculators/calculators/index.vue' /* webpackChunkName: "pages/calculators/calculators/index" */))
const _c091205a = () => interopDefault(import('../src/pages/calculators/types/index.vue' /* webpackChunkName: "pages/calculators/types/index" */))
const _8aba7d58 = () => interopDefault(import('../src/pages/event-triggers/triggers/index.vue' /* webpackChunkName: "pages/event-triggers/triggers/index" */))
const _24d8dc2e = () => interopDefault(import('../src/pages/feeds/feeds-list/index.vue' /* webpackChunkName: "pages/feeds/feeds-list/index" */))
const _1445207e = () => interopDefault(import('../src/pages/form/list/index.vue' /* webpackChunkName: "pages/form/list/index" */))
const _4ab63e9d = () => interopDefault(import('../src/pages/form/param-type/index.vue' /* webpackChunkName: "pages/form/param-type/index" */))
const _1c80bb5d = () => interopDefault(import('../src/pages/form/type/index.vue' /* webpackChunkName: "pages/form/type/index" */))
const _0b0c8331 = () => interopDefault(import('../src/pages/pages/pages/index.vue' /* webpackChunkName: "pages/pages/pages/index" */))
const _06804f66 = () => interopDefault(import('../src/pages/pages/templates/index.vue' /* webpackChunkName: "pages/pages/templates/index" */))
const _a98e7126 = () => interopDefault(import('../src/pages/review/list/index.vue' /* webpackChunkName: "pages/review/list/index" */))
const _18a42549 = () => interopDefault(import('../src/pages/review/param-type/index.vue' /* webpackChunkName: "pages/review/param-type/index" */))
const _5c47d9ee = () => interopDefault(import('../src/pages/review/type/index.vue' /* webpackChunkName: "pages/review/type/index" */))
const _a3d3e0ce = () => interopDefault(import('../src/pages/sending/email-template-groups/index.vue' /* webpackChunkName: "pages/sending/email-template-groups/index" */))
const _6079d70b = () => interopDefault(import('../src/pages/sending/email-templates/index.vue' /* webpackChunkName: "pages/sending/email-templates/index" */))
const _2cd2511d = () => interopDefault(import('../src/pages/sending/newsletters/index.vue' /* webpackChunkName: "pages/sending/newsletters/index" */))
const _314793e4 = () => interopDefault(import('../src/pages/sending/subscribers/index.vue' /* webpackChunkName: "pages/sending/subscribers/index" */))
const _650cc10a = () => interopDefault(import('../src/pages/seo/pages/index.vue' /* webpackChunkName: "pages/seo/pages/index" */))
const _3549cd4e = () => interopDefault(import('../src/pages/seo/robots/index.vue' /* webpackChunkName: "pages/seo/robots/index" */))
const _58ff388c = () => interopDefault(import('../src/pages/seo/search-logs/index.vue' /* webpackChunkName: "pages/seo/search-logs/index" */))
const _6fe80e82 = () => interopDefault(import('../src/pages/seo/templates/index.vue' /* webpackChunkName: "pages/seo/templates/index" */))
const _53bb45a5 = () => interopDefault(import('../src/pages/seo/wordadjective/index.vue' /* webpackChunkName: "pages/seo/wordadjective/index" */))
const _a98f9e6c = () => interopDefault(import('../src/pages/seo/wordcase/index.vue' /* webpackChunkName: "pages/seo/wordcase/index" */))
const _4edf5f44 = () => interopDefault(import('../src/pages/seo/wordplural/index.vue' /* webpackChunkName: "pages/seo/wordplural/index" */))
const _45c539f0 = () => interopDefault(import('../src/pages/seo/wordreplace/index.vue' /* webpackChunkName: "pages/seo/wordreplace/index" */))
const _3427c3d3 = () => interopDefault(import('../src/pages/settings/currencies/index.vue' /* webpackChunkName: "pages/settings/currencies/index" */))
const _7ebeb4a8 = () => interopDefault(import('../src/pages/settings/dealers/index.vue' /* webpackChunkName: "pages/settings/dealers/index" */))
const _3966a99e = () => interopDefault(import('../src/pages/settings/dev-config/index.vue' /* webpackChunkName: "pages/settings/dev-config/index" */))
const _311f3a92 = () => interopDefault(import('../src/pages/settings/email-config/index.vue' /* webpackChunkName: "pages/settings/email-config/index" */))
const _21022b51 = () => interopDefault(import('../src/pages/settings/payments/index.vue' /* webpackChunkName: "pages/settings/payments/index" */))
const _bfeb9ddc = () => interopDefault(import('../src/pages/settings/personnel/index.vue' /* webpackChunkName: "pages/settings/personnel/index" */))
const _258c19aa = () => interopDefault(import('../src/pages/settings/price-roles/index.vue' /* webpackChunkName: "pages/settings/price-roles/index" */))
const _5c64d41e = () => interopDefault(import('../src/pages/settings/regions/index.vue' /* webpackChunkName: "pages/settings/regions/index" */))
const _5d1fde22 = () => interopDefault(import('../src/pages/settings/roles/index.vue' /* webpackChunkName: "pages/settings/roles/index" */))
const _1e66266f = () => interopDefault(import('../src/pages/settings/shops/index.vue' /* webpackChunkName: "pages/settings/shops/index" */))
const _4a1af88c = () => interopDefault(import('../src/pages/settings/site-config/index.vue' /* webpackChunkName: "pages/settings/site-config/index" */))
const _5430f57e = () => interopDefault(import('../src/pages/settings/sites/index.vue' /* webpackChunkName: "pages/settings/sites/index" */))
const _60273816 = () => interopDefault(import('../src/pages/settings/status/index.vue' /* webpackChunkName: "pages/settings/status/index" */))
const _0f1736dc = () => interopDefault(import('../src/pages/settings/status-orders/index.vue' /* webpackChunkName: "pages/settings/status-orders/index" */))
const _76cdd505 = () => interopDefault(import('../src/pages/settings/tasks-config/index.vue' /* webpackChunkName: "pages/settings/tasks-config/index" */))
const _cea7e54a = () => interopDefault(import('../src/pages/settings/user-types/index.vue' /* webpackChunkName: "pages/settings/user-types/index" */))
const _35d821dc = () => interopDefault(import('../src/pages/shop/bonuses/index.vue' /* webpackChunkName: "pages/shop/bonuses/index" */))
const _73b0da58 = () => interopDefault(import('../src/pages/shop/carts/index.vue' /* webpackChunkName: "pages/shop/carts/index" */))
const _406e4266 = () => interopDefault(import('../src/pages/shop/categories/index.vue' /* webpackChunkName: "pages/shop/categories/index" */))
const _9665b0e0 = () => interopDefault(import('../src/pages/shop/custom-filters/index.vue' /* webpackChunkName: "pages/shop/custom-filters/index" */))
const _eab7bc5c = () => interopDefault(import('../src/pages/shop/delivery-groups/index.vue' /* webpackChunkName: "pages/shop/delivery-groups/index" */))
const _6f4eec25 = () => interopDefault(import('../src/pages/shop/discount-groups/index.vue' /* webpackChunkName: "pages/shop/discount-groups/index" */))
const _2190593a = () => interopDefault(import('../src/pages/shop/options/index.vue' /* webpackChunkName: "pages/shop/options/index" */))
const _3d840f56 = () => interopDefault(import('../src/pages/shop/orders/index.vue' /* webpackChunkName: "pages/shop/orders/index" */))
const _489e47c8 = () => interopDefault(import('../src/pages/shop/partners/index.vue' /* webpackChunkName: "pages/shop/partners/index" */))
const _79e5c6a3 = () => interopDefault(import('../src/pages/shop/product-groups/index.vue' /* webpackChunkName: "pages/shop/product-groups/index" */))
const _53fa1d95 = () => interopDefault(import('../src/pages/shop/products/index.vue' /* webpackChunkName: "pages/shop/products/index" */))
const _fc378930 = () => interopDefault(import('../src/pages/shop/promocodes/index.vue' /* webpackChunkName: "pages/shop/promocodes/index" */))
const _ca9476f2 = () => interopDefault(import('../src/pages/shop/stickers/index.vue' /* webpackChunkName: "pages/shop/stickers/index" */))
const _3eb9fc1c = () => interopDefault(import('../src/pages/shop/upload-files/index.vue' /* webpackChunkName: "pages/shop/upload-files/index" */))
const _7aabe0cd = () => interopDefault(import('../src/pages/shop/users/index.vue' /* webpackChunkName: "pages/shop/users/index" */))
const _1bb8d387 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f567b550 = () => interopDefault(import('../src/pages/calculators/calculators/_calculatorId/index.vue' /* webpackChunkName: "pages/calculators/calculators/_calculatorId/index" */))
const _549e9d9c = () => interopDefault(import('../src/pages/pages/pages/_id/index.vue' /* webpackChunkName: "pages/pages/pages/_id/index" */))
const _6d92bf58 = () => interopDefault(import('../src/pages/seo/search-logs/_query/index.vue' /* webpackChunkName: "pages/seo/search-logs/_query/index" */))
const _2a798f41 = () => interopDefault(import('../src/pages/shop/orders/_id/index.vue' /* webpackChunkName: "pages/shop/orders/_id/index" */))
const _22a3e95f = () => interopDefault(import('../src/pages/shop/product/_id/index.vue' /* webpackChunkName: "pages/shop/product/_id/index" */))
const _7d2796d9 = () => interopDefault(import('../src/pages/calculators/calculators/_calculatorId/elements/_elementId/index.vue' /* webpackChunkName: "pages/calculators/calculators/_calculatorId/elements/_elementId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/banner-block",
    component: _45a8e66c,
    name: "banner-block"
  }, {
    path: "/calculators",
    component: _c973bdae,
    name: "calculators"
  }, {
    path: "/collections",
    component: _0e7cc40d,
    name: "collections"
  }, {
    path: "/event-triggers",
    component: _3dca5e6c,
    name: "event-triggers"
  }, {
    path: "/feeds",
    component: _2dbfe6cd,
    name: "feeds"
  }, {
    path: "/form",
    component: _e1ab703c,
    name: "form"
  }, {
    path: "/list-waiting",
    component: _2e484bbc,
    name: "list-waiting"
  }, {
    path: "/news",
    component: _1e62819e,
    name: "news"
  }, {
    path: "/pages",
    component: _0d92993c,
    name: "pages"
  }, {
    path: "/profile",
    component: _796575a1,
    name: "profile"
  }, {
    path: "/raffles",
    component: _9cfbe3fa,
    name: "raffles"
  }, {
    path: "/review",
    component: _8d2c5694,
    name: "review"
  }, {
    path: "/search-text-replace",
    component: _fcbb2ade,
    name: "search-text-replace"
  }, {
    path: "/sending",
    component: _12907e12,
    name: "sending"
  }, {
    path: "/seo",
    component: _f6af2456,
    name: "seo"
  }, {
    path: "/settings",
    component: _32d25301,
    name: "settings"
  }, {
    path: "/shop",
    component: _29717f94,
    name: "shop"
  }, {
    path: "/signin",
    component: _51b5f880,
    name: "signin"
  }, {
    path: "/widgets",
    component: _6d5ccb27,
    name: "widgets"
  }, {
    path: "/calculators/calculators",
    component: _f19be86a,
    name: "calculators-calculators"
  }, {
    path: "/calculators/types",
    component: _c091205a,
    name: "calculators-types"
  }, {
    path: "/event-triggers/triggers",
    component: _8aba7d58,
    name: "event-triggers-triggers"
  }, {
    path: "/feeds/feeds-list",
    component: _24d8dc2e,
    name: "feeds-feeds-list"
  }, {
    path: "/form/list",
    component: _1445207e,
    name: "form-list"
  }, {
    path: "/form/param-type",
    component: _4ab63e9d,
    name: "form-param-type"
  }, {
    path: "/form/type",
    component: _1c80bb5d,
    name: "form-type"
  }, {
    path: "/pages/pages",
    component: _0b0c8331,
    name: "pages-pages"
  }, {
    path: "/pages/templates",
    component: _06804f66,
    name: "pages-templates"
  }, {
    path: "/review/list",
    component: _a98e7126,
    name: "review-list"
  }, {
    path: "/review/param-type",
    component: _18a42549,
    name: "review-param-type"
  }, {
    path: "/review/type",
    component: _5c47d9ee,
    name: "review-type"
  }, {
    path: "/sending/email-template-groups",
    component: _a3d3e0ce,
    name: "sending-email-template-groups"
  }, {
    path: "/sending/email-templates",
    component: _6079d70b,
    name: "sending-email-templates"
  }, {
    path: "/sending/newsletters",
    component: _2cd2511d,
    name: "sending-newsletters"
  }, {
    path: "/sending/subscribers",
    component: _314793e4,
    name: "sending-subscribers"
  }, {
    path: "/seo/pages",
    component: _650cc10a,
    name: "seo-pages"
  }, {
    path: "/seo/robots",
    component: _3549cd4e,
    name: "seo-robots"
  }, {
    path: "/seo/search-logs",
    component: _58ff388c,
    name: "seo-search-logs"
  }, {
    path: "/seo/templates",
    component: _6fe80e82,
    name: "seo-templates"
  }, {
    path: "/seo/wordadjective",
    component: _53bb45a5,
    name: "seo-wordadjective"
  }, {
    path: "/seo/wordcase",
    component: _a98f9e6c,
    name: "seo-wordcase"
  }, {
    path: "/seo/wordplural",
    component: _4edf5f44,
    name: "seo-wordplural"
  }, {
    path: "/seo/wordreplace",
    component: _45c539f0,
    name: "seo-wordreplace"
  }, {
    path: "/settings/currencies",
    component: _3427c3d3,
    name: "settings-currencies"
  }, {
    path: "/settings/dealers",
    component: _7ebeb4a8,
    name: "settings-dealers"
  }, {
    path: "/settings/dev-config",
    component: _3966a99e,
    name: "settings-dev-config"
  }, {
    path: "/settings/email-config",
    component: _311f3a92,
    name: "settings-email-config"
  }, {
    path: "/settings/payments",
    component: _21022b51,
    name: "settings-payments"
  }, {
    path: "/settings/personnel",
    component: _bfeb9ddc,
    name: "settings-personnel"
  }, {
    path: "/settings/price-roles",
    component: _258c19aa,
    name: "settings-price-roles"
  }, {
    path: "/settings/regions",
    component: _5c64d41e,
    name: "settings-regions"
  }, {
    path: "/settings/roles",
    component: _5d1fde22,
    name: "settings-roles"
  }, {
    path: "/settings/shops",
    component: _1e66266f,
    name: "settings-shops"
  }, {
    path: "/settings/site-config",
    component: _4a1af88c,
    name: "settings-site-config"
  }, {
    path: "/settings/sites",
    component: _5430f57e,
    name: "settings-sites"
  }, {
    path: "/settings/status",
    component: _60273816,
    name: "settings-status"
  }, {
    path: "/settings/status-orders",
    component: _0f1736dc,
    name: "settings-status-orders"
  }, {
    path: "/settings/tasks-config",
    component: _76cdd505,
    name: "settings-tasks-config"
  }, {
    path: "/settings/user-types",
    component: _cea7e54a,
    name: "settings-user-types"
  }, {
    path: "/shop/bonuses",
    component: _35d821dc,
    name: "shop-bonuses"
  }, {
    path: "/shop/carts",
    component: _73b0da58,
    name: "shop-carts"
  }, {
    path: "/shop/categories",
    component: _406e4266,
    name: "shop-categories"
  }, {
    path: "/shop/custom-filters",
    component: _9665b0e0,
    name: "shop-custom-filters"
  }, {
    path: "/shop/delivery-groups",
    component: _eab7bc5c,
    name: "shop-delivery-groups"
  }, {
    path: "/shop/discount-groups",
    component: _6f4eec25,
    name: "shop-discount-groups"
  }, {
    path: "/shop/options",
    component: _2190593a,
    name: "shop-options"
  }, {
    path: "/shop/orders",
    component: _3d840f56,
    name: "shop-orders"
  }, {
    path: "/shop/partners",
    component: _489e47c8,
    name: "shop-partners"
  }, {
    path: "/shop/product-groups",
    component: _79e5c6a3,
    name: "shop-product-groups"
  }, {
    path: "/shop/products",
    component: _53fa1d95,
    name: "shop-products"
  }, {
    path: "/shop/promocodes",
    component: _fc378930,
    name: "shop-promocodes"
  }, {
    path: "/shop/stickers",
    component: _ca9476f2,
    name: "shop-stickers"
  }, {
    path: "/shop/upload-files",
    component: _3eb9fc1c,
    name: "shop-upload-files"
  }, {
    path: "/shop/users",
    component: _7aabe0cd,
    name: "shop-users"
  }, {
    path: "/",
    component: _1bb8d387,
    name: "index"
  }, {
    path: "/calculators/calculators/:calculatorId",
    component: _f567b550,
    name: "calculators-calculators-calculatorId"
  }, {
    path: "/pages/pages/:id",
    component: _549e9d9c,
    name: "pages-pages-id"
  }, {
    path: "/seo/search-logs/:query",
    component: _6d92bf58,
    name: "seo-search-logs-query"
  }, {
    path: "/shop/orders/:id",
    component: _2a798f41,
    name: "shop-orders-id"
  }, {
    path: "/shop/product/:id",
    component: _22a3e95f,
    name: "shop-product-id"
  }, {
    path: "/calculators/calculators/:calculatorId/elements/:elementId",
    component: _7d2796d9,
    name: "calculators-calculators-calculatorId-elements-elementId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
