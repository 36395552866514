import { Page, PageKeys } from './interfaces'
import PagesModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export class Pages extends BasePlugin<Page, 'id', PageKeys, PagesModule> {
  removeItemPropByKeys = (...keys: PageKeys[]) => {
    this.module.removeItemPropByKeys(keys)
  }

  updateChildren = ({ api, id }: { api: 'sites' | 'roles'; id: number }): Promise<void> => {
    return this.module.updateChildren({ api, id })
  }
}

export default function getPluginApi (store: PagesModule) {
  return new Pages(store)
}
